import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Setup Your Dashboard",
  "description": "",
  "summary": "A guide on how to get credentials and domain whitelisting in dashboard"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "getting-credentials",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#getting-credentials",
        "aria-label": "getting credentials permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Getting Credentials`}</h2>
    <p>{`To communicate with LoginRadius you will need some details about the application. You can find those details in the `}<strong parentName="p">{`Api Credentials`}</strong>{` section of the `}<a parentName="p" {...{
        "href": "https://dashboard.loginradius.com/dashboard"
      }}>{`LoginRadius Dashboard`}</a>{`.`}</p>
    <p>{`The `}<strong parentName="p">{`API Key`}</strong>{` and `}<strong parentName="p">{`API Secret`}</strong>{` is used to interact with LoginRadius APIs.`}</p>
    <p>{`The `}<strong parentName="p">{`App Name`}</strong>{` is the unique name for each application in LoginRadius. The `}<strong parentName="p">{`Auth Page`}</strong>{` URL will be created using your app name only.`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`For eg:`}</strong>{` If App Name is `}<strong parentName="p">{`mynewapp`}</strong>{` then your Auth Page URL will be `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`https://mynewapp.hub.loginradius.com/auth.aspx`}</code>{`.`}</p>
    </blockquote>
    <div style={{
      "textAlign": "center"
    }}>
  <img src="/8978430c9f2350f54515c9044585dc99/app_credentials.png" alt="app_credentials" />
    </div>
    <h2 {...{
      "id": "domain-whitelisting",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#domain-whitelisting",
        "aria-label": "domain whitelisting permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Domain Whitelisting`}</h2>
    <p>{`This section allows you to add and manage the domain(s). LoginRadius processes the API calls that are received from the whitelisted domains. Local domains `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`(http://localhost and http://127.0.0.1)`}</code>{` are whitelisted by default.`}</p>
    <blockquote>
      <p parentName="blockquote">{`If you are implementing the SSO, make sure to whitelist the respective application domains.`}</p>
    </blockquote>
    <div style={{
      "textAlign": "center"
    }}>
  <img src="/616bd78bb714cc084399589ec9f9e087/domain_whitelisting.png" alt="Whitlist the domain" />
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      