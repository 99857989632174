import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Customize Auth Page (IDX - Identity Experience Framework)",
  "description": "",
  "summary": "A guide on how to select and customize Auth Page (IDX) themes"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The Section explains the available Auth Page (IDX) customization options:`}</p>
    <h2 {...{
      "id": "theme-selection",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#theme-selection",
        "aria-label": "theme selection permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Theme Selection`}</h2>
    <p>{`These themes do not require JavaScript to work, and it offers a simpler and faster experience for end-users. You can activate any of the themes shown below and then customize it if you want.`}</p>
    <div style={{
      "textAlign": "center"
    }}>
  <img src="/184811b61b26858ee6df99d6102d2986/auth_0.png" alt="auth_0" />
    </div>
    <br />
    <h2 {...{
      "id": "customization",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#customization",
        "aria-label": "customization permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Customization`}</h2>
    <p>{`You can customize the activated theme as per the requirements like logo, favicon, login page labels, etc. To proceed click the `}<strong parentName="p">{`Customize`}</strong>{` button highlighted in the following screen:`}</p>
    <div style={{
      "textAlign": "center"
    }}>
  <img src="/c23c3c002fc31ba68faeb2ef5c39ea06/auth_1.png" alt="auth_1" /> 
    </div>
    <br />
    <p>{`The following screen will appear:`}</p>
    <div style={{
      "textAlign": "center"
    }}>
  <img src="/c7dc6a021b04be2efd8471fee783e1c9/auth_2.png" alt="auth_2" />
    </div>
    <br />
    <p><strong parentName="p">{`General Configuration`}</strong>{`: You can manage the `}<strong parentName="p">{`Favicon`}</strong>{` and `}<strong parentName="p">{`Logo`}</strong>{` for your authentication pages as explained below: `}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Favicon`}</strong>{`: Enter the URL for your favicon resource, or upload one of your own.`}</li>
      <li parentName="ul"><strong parentName="li">{`Logo`}</strong>{`: Enter the URL for your logo resource, or upload one of your own.`}</li>
    </ul>
    <p>{`Click the `}<strong parentName="p">{`Save`}</strong>{` button to save the changes. To view the changes reflect, click the `}<strong parentName="p">{`Try It Out`}</strong>{` button from the top-right corner of the screen.`}</p>
    <p><strong parentName="p">{`Content Configuration`}</strong>{` You can change the default tab name of the Log In, Sign Up and Forgot Password interfaces with any custom name using the respective “Tab Name” field. You can also change/customize the title text displayed on the login screen for the social login options using the `}<strong parentName="p">{`Social Block Label field`}</strong>{`. The default text is `}<strong parentName="p">{`Log in with your Identity Provider`}</strong></p>
    <p>{`To start managing the content, click the `}<strong parentName="p">{`Content`}</strong>{` tab from the screen and the configuration option will appear on the screen as displayed below:`}</p>
    <div style={{
      "textAlign": "center"
    }}>
  <img src="/14c1eb34bded48bd4549ec7a1b543414/auth_3.png" alt="auth_3" />
    </div>
    <br />
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Login Label`}</strong>{`: You can change the Login Label as per your requirement example: Log In or Sign In`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Sign Up Label`}</strong>{`: You can change the Sign Up Label as per your requirement example: Sign Up or Registration`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Forget Password Label`}</strong>{`: You can change the Forget Password Label as per your requirement example: Forget Password`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Forgot Password Message`}</strong>{`: You can change the message that will be displayed to the user on submitting the forgot password request. The default message is ‘We will email you password reset instructions’.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Social Block Label`}</strong>{`: You can change the Social Block Label as per your requirement. Social Block Label is title displayed on the login screen as highlighted in the screen below:`}</p>
      </li>
    </ul>
    <div style={{
      "textAlign": "center"
    }}>
  <img src="/58ddc40fc19988d9e7764fd3d5a9aeed/auth_4.png" alt="auth_4" />
    </div>
    <br />
    <p>{`After making the desired changes, click the `}<strong parentName="p">{`Save`}</strong>{` button. To view the changes reflect, click the `}<strong parentName="p">{`Try It Out`}</strong>{` button from the top-right corner of the screen.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{`
`}<strong parentName="p">{`Try It Out button`}</strong>{` will navigate you to your Auth Page (IDX) where you can see all the changes. The URL of the Auth Page (IDX) is unique for your app. Your customers will be registering and logging on this page.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      