import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { ExpansionPanel, ExpansionPanelList, ExpansionPanelListItem } from 'gatsby-theme-apollo-docs';
import Customize from "../howto/authentication-theme";
export const _frontmatter = {
  "title": "Advanced Editor",
  "description": "",
  "summary": "An advanced editor section to customize the IDX auth and profile pages"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This feature allows you to choose a design template for your authentication pages (register, login, forgot password, and reset password). You can then customize Favicon, Logo, and Labels for the selected template. The default design theme remains applicable even if you do not choose a template option.`}</p>
    <h2 {...{
      "id": "access-the-advanced-editor",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#access-the-advanced-editor",
        "aria-label": "access the advanced editor permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Access the advanced editor`}</h2>
    <p>{`Login to your LoginRadius Dashboard account,from the left navigation panel, click the Auth Page (IDX) and then navigate to the Auth Page (IDX) section. Click the CUSTOMIZE button given on the selected theme as highlighted in the screen below:`}</p>
    <div style={{
      "textAlign": "center"
    }}>
  <img src="/0fbc60fc2e8f046eb38f8bd77f160da5/customize.png" alt="Customization" />
    </div>
    <br />
    <p>{`You can see an advanced editor section showing several files and their source codes, as shown in the screen below.`}</p>
    <div style={{
      "textAlign": "center"
    }}>
  <img src="/8743155ea94bd50e523c9d07ffb543f6/advanced-editor.png" alt="Advanced Editor" />
    </div>
    <br />
    <h2 {...{
      "id": "customization",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#customization",
        "aria-label": "customization permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Customization`}</h2>
    <p>{`The complete IDX consists of two main pages, one is Authentication and another one is the profile page. `}</p>
    <p>{`The `}<strong parentName="p">{`Authentication`}</strong>{` page is having the following interfaces:`}</p>
    <ul>
      <li parentName="ul">{`Registration`}</li>
      <li parentName="ul">{`Login`}</li>
      <li parentName="ul">{`Forgot Password`}</li>
    </ul>
    <p>{`The `}<strong parentName="p">{`Profile`}</strong>{` page is having the following interfaces:`}</p>
    <ul>
      <li parentName="ul">{`Profile Editor`}</li>
      <li parentName="ul">{`Reset Password`}</li>
    </ul>
    <p>{`Both sections allow you to add your custom CSS and JS by adding the properties or direct from the URL along with below categories.`}</p>
    <ol>
      <li parentName="ol">{`Markup`}</li>
      <li parentName="ol">{`Styling`}</li>
      <li parentName="ol">{`Script`}</li>
    </ol>
    <h3 {...{
      "id": "markup",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#markup",
        "aria-label": "markup permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Markup`}</h3>
    <p>{`The HTML layout of both `}<strong parentName="p">{`Authentication`}</strong>{` and `}<strong parentName="p">{`Profile`}</strong>{` page is customizable. Go into the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`HTML`}</code>{` tab in the `}<em parentName="p">{`Authentication`}</em>{` section and you can see the complete HTML code over here.  To populate the containers, we have to include some JavaScript handlers to help make the associations between LoginRadius scripts and the Identity Experience Framework Registration page.`}</p>
    <p>{`The HTML interfaces to handle different authentication functionalities are set to some default containers. Below is the table to list down default interfaces and their corresponding containers. `}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Interface`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default Container ID`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Related Section`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Login Form`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`login-container`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Authentication`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Registration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`registration-container`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Authentication`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Forgot Password`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`forgotpassword-container`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Authentication`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Social Linking Interface`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`lr-linked-social and lr-not-linked-social`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Profile`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Profile Editor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`profile-editor-container`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Profile`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Change Password`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`change-password`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Profile`}</td>
        </tr>
      </tbody>
    </table>
    <blockquote>
      <p parentName="blockquote">{`Note: While changing the markup, you need to change the corresponding IDs for the interfaces in.`}</p>
    </blockquote>
    <p>{`For example, if you want to change the ID of the default login-container then while changing the ID of the element in the markup file, you can need to update the same ID reference in its corresponding JavaScript file `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`default-auth-before-script.js`}</code>{` using this variable `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`lr_raas_settings.login.containerid`}</code>{`.`}</p>
    <h3 {...{
      "id": "styling",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#styling",
        "aria-label": "styling permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Styling`}</h3>
    <p>{`You can customize the CSS styling applied to the page by making changes to the provided `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`hosted-auth-default.css`}</code>{` according to your design/branding requirements.`}</p>
    <h3 {...{
      "id": "script",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#script",
        "aria-label": "script permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Script`}</h3>
    <p>{`You can control some additional functionality from `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`default-auth-before-script.js`}</code>{` and  `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`default-profile-before-script.js`}</code>{` JavaScript files to include any additional JS you would like to further customize the interfaces based on our documentation`}</p>
    <p>{`The following customizations are included in the provided JS files:`}</p>
    <p><strong parentName="p">{`To change the messages`}</strong>{`: Find the object `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`successMessages`}</code>{` in `}<em parentName="p">{`JavaScript files`}</em>{` and change the message according to your requirements.`}</p>
    <p><strong parentName="p">{`To change the Loading image`}</strong>{`: Find function `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`visibleLoadingSpinner`}</code>{` in `}<em parentName="p">{`JavaScript files`}</em>{` and customize it to your requirements.`}</p>
    <p><strong parentName="p">{`To change form settings`}</strong>{` : `}</p>
    <ul>
      <li parentName="ul">{`Find functions showLogin, showRegister, showForgotPassword in `}<strong parentName="li">{`default-auth-before-script.js`}</strong>{` and customize it to your requirements just below the default code.`}</li>
      <li parentName="ul">{`Find functions LoginRadiusRaaS.\\$hooks.setRenderProfileEditor in `}<strong parentName="li">{`default-profile-before-script.js`}</strong>{` and customize it to your requirements just below the default code.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      