import { MenuWrapper } from "../../../../src/components/menu.js";
import WorkoutMenuItem from "../../../../src/components/menu-item.js";
import { IconSupport } from "@apollo/space-kit/icons/IconSupport";
import { IconInfo } from "@apollo/space-kit/icons/IconInfo";
import * as React from 'react';
export default {
  MenuWrapper,
  WorkoutMenuItem,
  IconSupport,
  IconInfo,
  React
};